import HomePage from "./Home";
import DrinkPage from "./Drink";
import HistoryPage from "./History";
import HistoryDetailPage from "./HistoryDetail";
import AlcoholDetailPage from "./AlcoholDetail";
import SettingsPage from "./Settings";
import AppInfoPage from "./AppInfo";
import LoginPage from "./Auth";
import ProfilePage from "./Profile";
import ConfirmPhoneNumberPage from "./Auth/ConfirmPhoneNumber";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import ResetPasswordPage from "./Auth/ResetPassword";
import AccountManagementPage from "./AccountManagement";
import AccountUnsubscribePage from "./AccountUnsubscribe";
import ChangeEmailPage from "./ChangeEmail";
import ChangePasswordPage from "./ChangePassword";
import ChangePhoneNumberPage from "./ChangePhoneNumber";
import NotificationsPage from "./Notifications";
import NotificationDetailPage from "./NotificationDetail";
import PrivacyPolicyPage from "./PrivacyPolicy";
import TermsOfUsePage from "./TermsOfUse";
import AboutPage from "./About";
import VerificationEmailPage from "./VerificationEmail";
import SuccessUpdateEmailPage from "./SuccessUpdateEmail";
import ContactUsPage from "./ContactUs";
import SentContactUsPage from "./SentContactUs";
import TicketsPage from "./Tickets";
import QrGeneratePage from "./QrGenerate";
import NotFoundErrorPage from "./NotFoundError";
import CommercePage from "./Commerce";
import CompleteLogoutPage from "./CompleteLogout";
import FailedLogoutPage from "./FailedLogout";
import ImportQrTicketPage from "./ImportQrTicket";
import ImportTicketConfirmationPage from "./ImportTicketConfirmation";
import ImportTicketCompletePage from "./ImportTicketComplete";
import ImportTicketFailurePage from "./ImportTicketFailure";
import ManualRegistPage from "./Manual/Regist";
import ManualLoginPage from "./Manual/Login";
import ManualRecordPage from "./Manual/Record";
import ManualEnjoyPage from "./Manual/Enjoy";

export default {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  HomePage,
  DrinkPage,
  HistoryPage,
  HistoryDetailPage,
  AlcoholDetailPage,
  SettingsPage,
  AppInfoPage,
  ProfilePage,
  ConfirmPhoneNumberPage,
  AccountManagementPage,
  AccountUnsubscribePage,
  ChangePasswordPage,
  ChangeEmailPage,
  ChangePhoneNumberPage,
  NotificationsPage,
  NotificationDetailPage,
  PrivacyPolicyPage,
  TermsOfUsePage,
  AboutPage,
  VerificationEmailPage,
  SuccessUpdateEmailPage,
  ContactUsPage,
  SentContactUsPage,
  TicketsPage,
  QrGeneratePage,
  NotFoundErrorPage,
  CommercePage,
  CompleteLogoutPage,
  FailedLogoutPage,
  ImportQrTicketPage,
  ImportTicketConfirmationPage,
  ImportTicketCompletePage,
  ImportTicketFailurePage,
  ManualRegistPage,
  ManualLoginPage,
  ManualRecordPage,
  ManualEnjoyPage,
};
